
// import './AcademySalesPage.scss'
import '../../input.css'
import CoverPage from './CoverPage'
import LeanPyramidSection from './LeanPyramidSection'
// import ModulesSection from './ModulesSection/ModulesSection'
// import HotmartSection from './HotmartSection'
import StartPage from './StartPage'
import TeacherSection from './TeacherSection'
import TestimonialsPage from './TestimonialsSection/TestimonialsPage'
// import ScarcitySection from './ScarcitySection'
// import WarrantySection from './WarrantySection'
import LeanPyramidHistory from './LeanPyramidHistory'
import PersonasSection from './PersonasSection'
import LeanPyramidBenefitsSection from './LeanPyramidBenefitsSection'
// import OfferSection from './OfferSection'
// import FaqSection from './FaqSection'
import StickyCta from './StickyCta'
import Footer from './Footer'
// import BonusSection from './BonusSection'
import i18next from 'i18next'
// import AcademyNetflixSection from './AcademyNetflixSection'

import { useTranslation } from 'react-i18next'

const AcademySalesPage = () => {
  const [t] = useTranslation()
  const handleFlagClicked = e => {
    e.preventDefault()

    const language = e.target.getAttribute('data-tag')
    i18next.changeLanguage(language)
  }

  const FlagLink = ({ tag, image, alt }) => (
    <a
      onClick={handleFlagClicked}
      className='flex justify-center items-center'
      href='http://leanit101.com'
    >
      <img
        style={{ paddingTop: 0 }}
        data-tag={tag}
        className='flex h-1/2 m-1'
        src={`/images/${image}.png`}
        alt={alt}
      />
    </a>
  )

  const TranslationFlags = () => (
    <div className='flex items-end justify-center list-none w-24 h-full'>
      <li className='flex h-full w-7'>
        <FlagLink tag='pt-BR' image='pt-br' alt='Traduza para Português' />
      </li>
      <li className='flex h-full w-7'>
        <FlagLink tag='en-US' image='en-gb' alt='Translate to English' />
      </li>
      <li className='flex h-full w-7'>
        <FlagLink tag='es' image='es' alt='Traducir al español' />
      </li>
    </div>
  )

  return (
    <div
      id='AcademySalesPage'
      className='h-full w-full flex flex-col font-Barlow text-white  bg-steelGray'
    >
      <div className='relative top-0 mb-10'>
        <div className='bg-vulcan h-16 w-full flex justify-center items-center fixed z-50 '>
          <div className='bg-vulcan h-12 w-full flex justify-between items-center md:px-40 p-x-12'>
            <a className='h-16' href='/'>
              <img src='images/logo-leanit101-slogan.png' alt='Logo' className='h-full' />
            </a>
            <TranslationFlags />
          </div>
        </div>
        <div className='bg-leanit101Blue w-full flex justify-center items-center mt-16 p-5 py-10 md:py-3 md:fixed z-50 '>
          <div className=' h-12 w-full flex justify-center items-center md:px-40 p-x-12'>
            {t('home.message')}
          </div>
        </div>
      </div>

      <StickyCta className='relative bottom-0' />
      <CoverPage />
      <StartPage />
      <PersonasSection />
      <TeacherSection />
      <TestimonialsPage />
      <LeanPyramidHistory />
      <LeanPyramidSection />
      <LeanPyramidBenefitsSection />
      {/* <AcademyNetflixSection />
      <ModulesSection />
      <BonusSection />
      <HotmartSection />
      <OfferSection />
      <ScarcitySection />
      <WarrantySection />
      <FaqSection /> */}
      <div className='mb-[200px] '>
        <Footer />
      </div>

    </div>
  )
}

export default AcademySalesPage
